<template>
	<!-- begin login -->
	<div class="login login-with-news-feed">
		<!-- begin news-feed -->
		<div class="news-feed bg-teal-transparent-2">
			<div class="text-center pb-3" style="margin-top: 160px;">
				<img :src="logoSumut" width="200" alt="logoSumut">
			</div>
			<div>
				<h3 class="d-flex justify-content-center">PEMERINTAH PROVINSI SUMATERA UTARA</h3>
				<h3 class="d-flex justify-content-center">BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH</h3>
				<p class="font-weight-bold d-flex justify-content-center" style="font-size: 15px">Jl. Sisingamangaraja Km 5,5 Medan</p>
			</div>
		</div>
		<!-- end news-feed -->
		<!-- begin right-content -->
		<div class="right-content">
			<!-- begin login-header -->
			<div class="login-header">
				<div class="brand">
					<!-- <span>
						<img :src="logoSumut" width="60" alt="logoSumut">
					</span> --> E-RETRIBUSI 
					<small>Silahkan masukkan alamat email dan kata sandi untuk masuk ke e-retribusi</small>
				</div>
				<div class="icon">
					<i class="fa fa-sign-in"></i>
				</div>
			</div>
			<!-- end login-header -->
			<!-- begin login-content -->
            <b-alert variant="danger" show v-if="message" dismissible>
                {{ message }}
            </b-alert>
			<div class="login-content">
				<form v-on:submit.prevent="checkForm" method="POST" class="margin-bottom-0">
					<div class="form-group m-b-15">
						<input type="text" v-model="form.email" :class="{'form-control': true, 'form-control-lg': true, 'is-invalid': form.errors.has('email')}" placeholder="Alamat Email" :disabled="isDisable"/>
                        <em
                            v-if="form.errors.has('email')"
                            class="form-text text-danger"
                            >{{ errors.email[0] }}</em
                        >
					</div>
					<div class="form-group m-b-15">
						<div class="position-relative">
                            <input :type="inputType" v-model="form.password" :class="{'form-control': true, 'form-control-lg': true, 'is-invalid': form.errors.has('password')}" placeholder="Kata Sandi" :disabled="isDisable" style="padding-right: 35px;"/>
                            <div class="position-absolute text-right" style="top: 30%;right: 10px;">
                                <i class="fas fa-eye-slash" style="font-size: 18px;cursor: pointer;" v-if="hidePassword" @click="showPassword"></i>
                                <i class="fas fa-eye" style="font-size: 18px;cursor: pointer;" v-else @click="showPassword"></i>
                            </div>
                        </div>
                        <em
                            v-if="form.errors.has('password')"
                            class="form-text text-danger"
                            >{{ errors.password[0] }}</em
                        >
					</div>
					<!-- <div class="checkbox checkbox-css m-b-30">
						<input type="checkbox" id="remember_me_checkbox" value="" />
						<label for="remember_me_checkbox">
						Ingat Saya
						</label>
					</div> -->
					<div class="login-buttons">
						<button type="submit" class="btn btn-success btn-block btn-lg" :disabled="isDisable">
                            <span v-if="isLoading">
                                <b-spinner label="Spinning" small></b-spinner>
                            </span>
                            Masuk
                        </button>
					</div>
					<hr />
                    <div class="text-left my-2">
                        <p>Untuk mendaftarkan akun wajib retribusi atau akun pegawai, silahkan klik pada tombol di bawah ini.</p>
                    </div>
                    <div class="my-2">
                        <b-button variant="outline-success" block @click="regWajibRet">
                            Pendaftaran Akun Wajib Retribusi
                        </b-button>
                         <b-button variant="outline-success" block @click="regPegawai">
                            Pendaftaran Pegawai
                        </b-button>
                    </div>
					<p class="text-center text-grey-darker">
						&copy; Pemerintah Provinsi Sumatera Utara | All Right Reserved 2020
					</p>
				</form>
			</div>
			<!-- end login-content -->
		</div>
		<!-- end right-container -->
	</div>
	<!-- end login -->
</template>

<script>
/* eslint-disable */
    // eslint-disable-next-line
    // eslint-disable vue/no-unused-vars
    // eslint-disable-line no-unused-vars

import PageOptions from '@/config/PageOptions.vue'
import Logo from '@/assets/images/logoSumut.png'

export default {
	data () {
		return {
			logoSumut: Logo,
			form: new Form({
				email: '',
				password: ''
			}),
			errors: [],
            isLoading: false,
            isDisable: false,
            message: '',
            hidePassword: false,
            inputType: 'password'
		}
	},
	created() {
		PageOptions.pageEmpty = true;
		document.body.className = 'bg-white';
	},
	beforeRouteLeave (to, from, next) {
		PageOptions.pageEmpty = false;
		document.body.className = '';
		next();
	},
	methods: {
		checkForm: function(e) {
			e.preventDefault()
                // if (this.form.password.length > 0) {
                    // this.$Progress.start();
                    //this.btnLogin.Saving = true;
                    this.isLoading = true
                    this.isDisable = true
                    this.form
                        .post("/api/login")
                        .then(
                            response => {
                                this.isLoading = false
                                this.isDisable = false
                                // let is_admin = 1;
                                // let is_admin = response.data.user.is_admin;
                                localStorage.setItem('ER_token', response.data.data.token)
                                localStorage.setItem('user', JSON.stringify(response.data.data.user))
                                localStorage.setItem('roles', JSON.stringify(response.data.data.role))
                                localStorage.setItem('permissions', JSON.stringify(response.data.data.permission))
                                localStorage.setItem('pegawai', JSON.stringify(response.data.data.pegawai))

                                const wakil = {
                                    pimpinan: '',
                                    penanggung_jawab: ''
                                }

                                localStorage.setItem('wakil', JSON.stringify(wakil))

                                // set roles vue gates 
                                this.$gates.setRoles(response.data.data.role)
                                /**
                                 * Jika terjadi upgrade Role dan Permission, 
                                 * auth berubah otomatis setelah melakukan Refresh all
                                 * jika setRoles dan setPermission diletakkan di App.vue
                                 */
                                /**
                                 * Jika terjadi upgrade Role dan Permission, 
                                 * auth berubah otomatis setelah Login ulang
                                 * jika setRoles dan setPermission diletakkan di Login.vue
                                 */
                                // const roles = JSON.parse(localStorage.getItem('roles'));
                                // this.$laravel.setRoles(roles);
                                // const permissions = JSON.parse(localStorage.getItem('permissions'));
                                // this.$laravel.setPermissions(permissions);
                                /** 
                                 * Jika terjadi upgrade Role dan Permission, 
                                 * Kemudian auth berubah otomatis, 
                                 * jika setRoles dan setPermission diletakkan di setiap menu
                                 * atau membuat socket khusus setRole dan setPermission
                                 * setRoles dan setPermission memanggil langsung ke Database
                                 */
                                // const permissions = axios.get('/api/permissions')
                                // this.$laravel.setPermissions(permissions);
                                // const roles = axios.get('/api/roles')
                                // this.$laravel.setRoles(roles);
                                // ---
                                // this.$Progress.finish();
                                /* this.btnLogin.Saving = false;
                                // this.$router.push('/ourzone') */
                                if (localStorage.getItem('ER_token') != null) {
                                    this.$emit('loggedIn')
                                    if (this.$route.params.nextUrl != null) {
                                        this.$router.push(this.$route.params.nextUrl)
                                    } else {
                                        this.$router.push('/dashboard')
                                    }
                                }
                            },
                            error => {
                                this.isLoading = false
                                this.isDisable = false
                                //this.$Progress.fail();
                                //this.btnLogin.Saving = false;
                                if (error.response.status == 403) {
                                    this.form.reset();
                                    this.form.clear();
                                    this.$swal({
                                        title: "Warning",
                                        text: error.response.data.message,
                                        icon: "warning"
                                    }).then((result) => {
                                    })
                                }else if(error.response.status == 422) {
                                    this.errors = error.response.data;
                                }else if(error.response.status == 404) {
                                    // ...
                                } else if (error.response.status === 401) {
                                    this.message = error.response.data.status.message
                                }else{
                                    this.$snotify.error(
                                        "Something went wrong try again later.",
                                        "Error"
                                    );
                                }
                            }
                        ).catch(e => {
                            // this.errors = e.response.data.errors;
                            //this.$Progress.fail();
                            //this.btnLogin.Saving = false;
                            this.$snotify.error(
                                "Something went wrong try again later.",
                                "Error"
                            );
                        });
                // }
		},
        regWajibRet () {
            this.$router.push({ name: 'RegistrasiWajibRet' })
        },
        regPegawai () {
            this.$router.push({ name: 'RegistrasiPegawai' })
        },
        showPassword: function () {
            if (this.hidePassword) {
                this.hidePassword = false
                this.inputType = 'password'
            } else {
                this.hidePassword = true
                this.inputType = 'text'
            }
        }
	}
}
</script>